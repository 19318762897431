<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <b-card class="mb-0">
        <!-- logo -->
        <b-link
          :to="{ path: '/' }"
          class="brand-logo"
        >
          <logo />
          <h2 class="brand-text text-primary ml-1">
            {{ appName }}
          </h2>
        </b-link>

        <b-card-title class="mb-1">
          {{ $t('auth.Reset Password') }} 🔒
        </b-card-title>
        <b-card-text class="mb-2">
          {{ $t('auth.Your new password must be different from previously used passwords') }}
        </b-card-text>

        <!-- form -->
        <validation-observer
          ref="resetPassForm"
          #default="{invalid}"
        >
          <b-form
            class="auth-reset-password-form mt-2"
            @submit.prevent="validationForm"
          >
            <!-- password -->
            <b-form-group
              label-for="password"
              :label="$t('auth.Password')"
            >
              <validation-provider
                v-slot="{ errors }"
                :name="$t('Password')"
                rules="min:8|required|confirmed:@confirmation"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    :state="errors.length > 0 ? false:null"
                    placeholder="········"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password confirmation -->
            <b-form-group
              v-if="password"
              label-for="password-confirmation"
              :label="$t('auth.Password Confirmation')"
            >
              <validation-provider
                v-slot="{ errors }"
                :name="$t('auth.Password Confirmation')"
                rules="required"
                vid="confirmation"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password-confirmation"
                    v-model="passwordConfirmation"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    :state="errors.length > 0 ? false:null"
                    placeholder="········"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
                <div class="py-1">
                  <b-alert
                    v-if="!!alert"
                    show
                    :variant="alertVariant"
                  >
                    <div class="alert-body">
                      <feather-icon
                        :icon="alertVariant==='success'?'CheckCircleIcon':'AlertCircleIcon'"
                        class="mr-50 mt-25"
                      />
                      {{ $t(`auth.${alert}`) }}
                    </div>
                  </b-alert>

                  <!-- submit button -->
                  <b-button
                    block
                    type="submit"
                    variant="primary"
                    :disabled="!!alert||!!errors.length"
                  >
                    <b-spinner
                      v-if="submittingFetch"
                      type="grow"
                      small
                    />
                    {{ $t('auth.Set New Password') }}
                  </b-button>
                </div>
              </validation-provider>
            </b-form-group>

          </b-form>
        </validation-observer>

        <p class="text-center mt-2">
          <b-link :to="{name:'auth-login'}">
            <feather-icon icon="ChevronLeftIcon" /> {{ $t('auth.Back to login') }}
          </b-link>
        </p>

      </b-card>
    </div>
  </div>

</template>

<script>
import Logo from '@core/layouts/components/Logo.vue'
import {
  ValidationProvider, ValidationObserver, extend,
} from 'vee-validate'
import {
  required,
} from '@validations'

import {
  BLink, BButton, BForm, BCard, BCardTitle, BCardText, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BAlert, BSpinner,
} from 'bootstrap-vue'

import i18n from '@/libs/i18n'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

import { $themeConfig } from '@themeConfig'
import router from '@/router'
import store from '@/store'

import { ref } from '@vue/composition-api'

extend('confirmed', {
  params: ['target'],
  validate(value, { target }) {
    return target ? value === target : true
  },

  message: i18n.t('auth.password_confirmation_does_not_match'),
})
export default {
  components: {
    Logo,
    BCard,
    BButton,
    BCardTitle,
    BCardText,
    BForm,
    BFormGroup,
    BInputGroup,
    BLink,
    BFormInput,
    BInputGroupAppend,
    BAlert,
    BSpinner,
    ValidationProvider,
    ValidationObserver,

  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      // validation
      required,
      password: '',
      passwordConfirmation: '',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },

  },
  mounted() {
    this.token = router.currentRoute.query.token
    this.userEmail = localStorage.getItem('_userEmail')
  },
  methods: {
    sendPasswordResetRequest() {
      this.submittingFetch = true
      const data = {
        email: this.userEmail,
        token: this.token,
        password: this.password,
        password_confirmation: this.passwordConfirmation,
      }
      store
        .dispatch('account/sendPasswordResetRequest', data)
        .then(response => {
          const successStatuses = [200, 201]
          if (successStatuses.includes(response.status)) {
            this.alertVariant = 'success'
            localStorage.removeItem('_userEmail')
          }
          this.alert = response.data.message
        })
        .catch(error => {
          this.alert = 'Something went wrong'
        })
        .finally(() => {
          this.submittingFetch = false
        })
    },
    validationForm() {
      this.$refs.resetPassForm.validate().then(success => {
        if (success) {
          this.sendPasswordResetRequest()
        }
      })
    },
  },
  setup() {
    const token = ref('')
    const userEmail = ref('')
    const alert = ref('')
    const alertVariant = ref('danger')
    const submittingFetch = ref(false)
    const { appName } = $themeConfig.app
    return {
      appName,
      token,
      userEmail,
      alertVariant,
      alert,
      submittingFetch,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
